<template>
	<div class="page pageContainer" style="height: 100%;">
		<el-row>
			<span style="font-size: 14px;">考试名称：</span>
			<el-input v-model="searchForm.keyword" size="small" placeholder="请输入查询关键字"
				style="max-width: 200px;margin-right: 10px; margin-left: 10px;" />

			<el-select @change="setCourseName" v-model="searchForm.c_id" size="small"
				style="width: 150px; margin-right: 20px;">
				<el-option label="全部" :value="0"></el-option>
				<el-option v-for="(item,idx) in CourseList" :key="idx" :label="item.course_name" :value="item.id">
				</el-option>

			</el-select>




			<el-button size="small" icon="el-icon-search" @click="getList" plain type="primary">查询</el-button>


		</el-row>

		<div class="cbox" style="padding: 20px 0;">

			<el-tabs v-model="activeName" type="border-card" style="width: 100%">
				<el-tab-pane :label="activeName" :name="activeName">
					<el-table stripe :data="tableData" size="mini" border stripe @sort-change="changeTableSort">

						<el-table-column prop="id" width="80" label="序号">
							<template slot-scope="scope">
								{{scope.$index+1}}

							</template>
						</el-table-column>
						<el-table-column prop="course_name" label="所属课程" width="140"></el-table-column>
						<el-table-column prop="exam_title" label="试卷名称" min-width="100"></el-table-column>
						<el-table-column prop="exam_title" label="考试时间" width="280">
							<template slot-scope="scope">
								<span>{{scope.row.sdate}}</span>至
								<span>{{scope.row.edate}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="tmcount" label="状态" width="100">
							<template slot-scope="scope">
								<span v-if="scope.row.timestatus=='future'">未开始</span>
								<span v-if="scope.row.timestatus=='over'">已结束</span>
								<span v-if="scope.row.timestatus=='intime'" style="color: #009900;">进行中</span>
								
							</template>
						</el-table-column>

						<el-table-column prop="tmcount" label="题目数" width="100">

						</el-table-column>
						<el-table-column prop="tscore" label="总分数" width="100">

						</el-table-column>
						<el-table-column prop="join_stu" label="参与人数" width="100">
							<template slot-scope="scope">
								<span style="color: #007AFF; cursor: pointer;" title="点击查看参与人" @click="showJoinUsers(scope.row)" >{{scope.row.join_stu?scope.row.join_stu:0}}人(查看)</span>
								
							</template>
						</el-table-column>
						
						<el-table-column prop="join_stu" label="PC答题链接" width="100">
							<template slot-scope="scope">
								<span :id="'link'+scope.row.id" style="color: #007AFF; cursor: pointer;" title="点击复制链接" :data-clipboard-text="outLink+scope.row.id" 
								@click="copyLink(scope.row.id)" >点击复制</span>
							</template>
						</el-table-column>
						<el-table-column fixed="right" label="操作" width="160">
							<template slot-scope="scope">
								<el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消'
									icon="el-icon-info" iconColor="red" @confirm="handleDel(scope.row)" placement="left"
									title="是否确定删除？">
									<a slot="reference" size="small"
										style="color: red;font-size: 12px;margin-right: 10px; cursor: pointer;">删除</a>
								</el-popconfirm>
								<el-button @click="handleModify(scope.row)" type="text" size="small">修改</el-button>
								<el-button @click="viewTM(scope.row)" type="text" size="small">题目</el-button>
								<el-button @click="viewTj(scope.row)" type="text" size="small">统计</el-button>

							</template>
						</el-table-column>


					</el-table>
					<el-pagination @current-change="handlePageChange" :current-page="page.current_page"
						:page-size="page.per_page" :total="page.count" layout="total, prev, next, jumper">
					</el-pagination>
				</el-tab-pane>
				<el-tab-pane disabled>
					<div slot="label">
						<el-button size="mini" icon="el-icon-plus" @click="formAdd" type="primary">新增考试</el-button>
					</div>
				</el-tab-pane>
			</el-tabs>


		</div>


		<el-dialog title="考试编辑" :visible.sync="dialogShow" :close-on-click-modal="false" width="800px" top="60px">
			<el-form :model="formData" ref="ruleForm" label-width="120px">
				<el-form-item label="所属课程：" prop="qst_title">
					<el-select v-model="formData.c_id" size="small" style="width: 250px; margin-right: 20px;">
						<el-option label="请选择课程" :value="0"></el-option>
						<el-option v-for="(item,idx) in CourseList" :key="idx" :label="item.course_name"
							:value="item.id">
						</el-option>

					</el-select>
				</el-form-item>


				<el-form-item label="试卷名称：" prop="qst_title">
					<el-input v-model.trim="formData.exam_title" placeholder="请输入题目标题" style="width: 100%" />
				</el-form-item>
				<el-form-item label="试卷头图：" prop="qst_title">
					<el-upload style="display: inline-block; margin-right: 20px;" action="" :http-request="uploadOss"
						:on-success="uploadTMFiles" :show-file-list="false" name="image">

						<el-button v-if="!formData.headimg" title="传图" size="small" type="primary"
							icon="el-icon-picture-outline-round" circle=""></el-button>
						<img v-viewer v-else :src="formData.headimg"
							style="width: 32px; height: 32px; vertical-align: middle; margin-right: 20px;">




					</el-upload>
				</el-form-item>
				<el-form-item label="试卷描述：" prop="exam_desc">
					<el-input type="textarea" :rows="2" v-model.trim="formData.exam_desc" placeholder="请输入题目描述"
						style="width: 100%" />
				</el-form-item>

				<el-row>
					<el-col :span="12">
						<el-form-item label="开始时间：" prop="exam_desc">
							<el-date-picker v-model="formData.sdate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
							</el-date-picker>
						</el-form-item>
						
						

					</el-col>
					<el-col :span="12">
						
						
						<el-form-item label="结束时间：" prop="exam_desc">
							<el-date-picker v-model="formData.edate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
							</el-date-picker>
						</el-form-item>
					
					</el-col>
				</el-row>
				
				<el-form-item label="答卷时长：" prop="qst_title">
					<el-input type="number" v-model.trim="formData.minute" placeholder="请输入分钟" style="width: 150px" /> 分钟
				</el-form-item>







			</el-form>
			<el-row style="margin-top: 20px;">
				<el-col :span="24" style="text-align: right;">
					<el-button size="small" type="default" icon="el-icon-close" @click="dialogShow = false">取消
					</el-button>
					<el-button size="small" type="primary" icon="el-icon-check" @click="submitForm('ruleForm')">保存
					</el-button>
				</el-col>
			</el-row>

		</el-dialog>


		<el-dialog :title="formData.exam_title" :visible.sync="dialogTmShow" :close-on-click-modal="false" width="80%"
			top="60px">
			<div id="tmbox">
				<div style="float: left; width: 48%; height: 100%;">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>题库</span>
							<el-button @click="dialogTmSjShow=true" style="float: right; padding: 3px 0;" type="text">随机添加
							</el-button>
							<el-button @click="setChooseTm" style="float: right; padding: 3px 0; margin-right: 20px;" type="text">添加选中
							</el-button>
							
						</div>
						<div class="cardbox" style="overflow: auto;">
							<el-table stripe :data="TMList" size="mini" border stripe
								@selection-change="handleSelectionChange1">
								<el-table-column type="selection" width="55">
								</el-table-column>
								<el-table-column prop="id" width="80" label="序号">
									<template slot-scope="scope">
										{{scope.$index+1}}

									</template>
								</el-table-column>

								<el-table-column prop="qst_title" label="题目标题" min-width="100"></el-table-column>
								<el-table-column prop="qst_type" label="题目类型" width="100">
									<template slot-scope="scope">
										<span v-if="scope.row.qst_type=='raido'">单选题</span>
										<span v-if="scope.row.qst_type=='checkbox'">多选题</span>
										<span v-if="scope.row.qst_type=='panduan'">判断题</span>
										<span v-if="scope.row.qst_type=='tiankong'">填空题</span>
										<span v-if="scope.row.qst_type=='zhuguan'">主观题</span>
									</template>
								</el-table-column>
								<el-table-column prop="score" label="分数" width="100" sortable="custom">
								</el-table-column>


							</el-table>
						</div>
					</el-card>
				</div>

				<div style="float: left; width: 4%; height: 100%; ">
					<img :src="'./images/change.png'" style="width: 100%; margin-top: 200px;" />
				</div>

				<div style=" width: 48%; height: 100%; margin-left: 50%;">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>试卷题目（共{{ExamTMList.length}}题，总分：{{totalScore}}分）</span>
							
							<el-button  icon="el-icon-download" type="text" style="float: right;padding: 3px 0;" @click="showExport=true">导入题目</el-button>
							<el-button @click="cancelChooseTm" style="float: right; padding: 3px 0; margin-right: 20px;" type="text">移除选中
							</el-button>
						</div>
						<div class="cardbox" style="overflow: auto;">
							<el-table stripe :data="ExamTMList" size="mini" border stripe
								@selection-change="handleSelectionChange2">
								<el-table-column type="selection" width="55">
								</el-table-column>
								<el-table-column prop="id" width="80" label="序号">
									<template slot-scope="scope">
										{{scope.$index+1}}

									</template>
								</el-table-column>

								<el-table-column prop="qst_title" label="题目标题" min-width="100"></el-table-column>
								<el-table-column prop="qst_type" label="题目类型" width="100">
									<template slot-scope="scope">
										<span v-if="scope.row.qst_type=='radio'">单选题</span>
										<span v-if="scope.row.qst_type=='checkbox'">多选题</span>
										<span v-if="scope.row.qst_type=='panduan'">判断题</span>
										<span v-if="scope.row.qst_type=='tiankong'">填空题</span>
										<span v-if="scope.row.qst_type=='zhuguan'">主观题</span>
									</template>
								</el-table-column>
								<el-table-column prop="score" label="分数" width="100" sortable="custom">
								</el-table-column>

								<el-table-column prop="sort" label="排序号" width="100" sortable="custom">
									<template slot-scope="scope">
										<div @click="editrowid=scope.row.id" v-if="editrowid!=scope.row.id">
											{{scope.row.sort}}</div>
										<el-input size="mini" v-else v-model="scope.row.sort"
											@blur="saveSort(scope.row)"></el-input>
									</template>
								</el-table-column>


							</el-table>
						</div>
					</el-card>
				</div>


			</div>
		</el-dialog>
		
		<el-dialog title="添加随机题目" :visible.sync="dialogTmSjShow" :close-on-click-modal="false" width="600px"
			top="200px">
			<el-form :model="suiji" ref="ruleForm" label-width="120px">
				<el-row>
					<el-col :span="8">
						<el-form-item label="单选题题目数：">
							<el-input size="small" v-model="suiji.radio" placeholder="数字"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="多选题题目数：">
							<el-input size="small" v-model="suiji.checkbox" placeholder="数字"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="判断题题目数：">
							<el-input size="small" v-model="suiji.panduan" placeholder="数字"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				
				<div  style="padding: 20; text-align: right;">
					<el-button @click="createRmTm"  type="primary">确定添加
					</el-button>
				</div>
				
				
				</el-form>
				
		</el-dialog>

		<result v-if="showResult" :exam_id="editrowid"></result>
		<joinuser v-if="showUsers" :exam_id="editrowid" :examtitle="examTitle"></joinuser>
		
		<el-dialog title="导入题目" :visible.sync="showExport" :close-on-click-modal="false" width="500px" top="200px">
			<div style="color: orange; padding-bottom: 50px;">
				请按照模板格式导入试题，不清楚导入文件格式，请<span style="color: #0000FF;"><el-link type="primary" href="/xls/tm_temp.xlsx" target="_blank" style="margin-left: 20px;">下载导入模板</el-link></span>
			</div>
			<div style="text-align: right;">
				<el-upload style="display: inline-block; margin-right: 20px; margin-left: 10px" action=""
					:http-request="uploadTemp" :on-success="uploadFiles" :show-file-list="false" name="image">
					<el-button size="small" icon="el-icon-upload2" type="primary">选择文件</el-button>
				</el-upload>
			</div>
		
		</el-dialog>
	</div>
</template>

<script>
	import Clipboard from "clipboard";
	import result from "./result.vue"
	import joinuser from "./joinUsers.vue"
	export default {
		components:{result,joinuser,Clipboard},
		data() {
			return {
				outLink:window.location.protocol+"//"+window.location.host+"/online?pmid=2&exma_id=",
				searchForm: {
					c_id: 0,
					exam_type: 0,
					keyword: "",
					ktype: "exam_title"
				},
				activeName: "全部课程",
				tableData: [],
				CourseList: [],
				page: {
					count: 1,
					current_page: 1,
					per_page: 20,
					total_page: 1
				},
				tmid: 0,
				dialogShow: false,
				dialogTmSjShow:false,
				dialogTmShow: false,
				showExport:false,
				formData: {
					id: 0,
					c_id: 0,
					exam_title: "",
					exam_type: 0,
					status: 1,
					exam_desc: "",
					headimg: "",
					sdate: "",
					edate: "",
					minute:""

				},
				TMList: [],
				ExamTMList: [],
				totalScore: 0,
				choosedTm1: [],
				choosedTm2: [],
				editrowid: 0,
				showResult:false,
				showUsers:false,
				examTitle:"",
				pmid:2,
				suiji:{
					radio:"",
					checkbox:"",
					panduan:""
				}
			}
		},


		mounted() {
			this.getCourseList()
			
		},
		methods: {
			copyLink(id) {
			    var clipboard = new Clipboard('#link'+id);
			      clipboard.on('success', e => {
			        this.$message.success("复制成功");
			        // 释放内存
			        clipboard.destroy()
			      })
			 },
			getCourseList() {
				let _this = this
				this.$http.post("/api/t_sch_course_list", {
					pagesize: 100
				}).then(res => {
					this.CourseList = res.data.data
				})
				this.$http.post("/api/get_teacher_course").then(res => {
					if(res.data.length>0){
						this.searchForm.c_id = res.data[0].id
					}
					this.getList()
				})
			},
			setCourseName() {
				if (this.searchForm.c_id > 0) {
					for (let item of this.CourseList) {
						if (item.id == this.searchForm.c_id) {
							this.activeName = item.course_name
						}
					}
				} else {
					this.activeName = "全部课程"
				}
				this.getList()

			},
			getList() {
				this.closeTm()
				this.searchForm.page = this.page.current_page;
				//http://mmnet.oicp.vip
				this.$http.post("/api/t_exam_admin_list", this.searchForm).then(res => {
					let ntime = res.data.time
					for(let item of  res.data.data){
						if(item.sdate&&item.edate){
							item.stime = new Date(item.sdate).getTime();
							item.etime = new Date(item.edate).getTime();
							
							if(ntime>item.stime&&ntime<item.etime){
								item.timestatus="intime"
							}else if(ntime>item.etime){
								item.timestatus="over"
							}else if(ntime<item.stime){
								item.timestatus="future"
							}
						
						}
					}
					
					this.tableData = res.data.data;
					this.page = res.data.page
					this.outLink = window.location.protocol+"//"+window.location.host+"/online?pm="+res.data.data[0].pmid+"&exam="
				})

			},
			handlePageChange(e) {
				this.page.page.current_page = e
				this.getList()
			},
			formAdd() {

				this.formData = {
					id: 0,
					c_id: this.searchForm.c_id,
					exam_title: "",
					exam_type: 0,
					status: 1,
					exam_desc: "",
					headimg: "",
					sdate: "",
					edate: ""

				}

				this.dialogShow = true
			},
			closeTm() {
				this.tmid = 0;
				this.showTM = false
			},
			handleModify(item) {
				this.formData = item
				this.dialogShow = true

			},
			handleDel(row) {
				this.$http.post("/api/t_exam_delete", {
					id: row.id
				}).then(res => {
					this.$message.success("删除成功")
					this.getList()


				})
			},
			changeTableSort(column) {
				this.searchForm.orderby = column.prop + " " + (column.order == "ascending" ? 'asc' : 'desc')
				this.getList()
			},
			uploadTMFiles(e) {
				console.log(e)
				this.formData.headimg = e.src
			},
			submitForm() {
				if (!this.formData.c_id) {
					this.$message.error("请选择课程")
					return
				}
				if (!this.formData.exam_title) {
					this.$message.error("请输入试卷标题")
					return
				}
				
				if (!this.formData.sdate) {
					this.$message.error("请输入开始时间")
					return
				}
				
				if (!this.formData.edate) {
					this.$message.error("请输入结束时间")
					return
				}
				if (!this.formData.minute) {
					this.$message.error("请输入答题时长")
					return
				}

				


				this.$http.post("/api/exam_edit", this.formData).then(res => {
					this.$message.success("保存成功")
					this.getList();
					this.dialogShow = false

				})
			},
			viewTM(row) {
				this.formData = row
				this.dialogTmShow = true
				setTimeout(() => {
					$("#tmbox").height($(window).height() - 200)
					$(".cardbox").height($(window).height() - 300)
					this.getTkTm()

				}, 300)
			},
			viewTj(e){
				this.editrowid = e.id
				this.showResult = true;
				
			},
			getTkTm() {
				this.$http.post("/api/tk_timu_list", {
					course_id: this.formData.c_id,
					pagesize: 2000
				}).then(res => {
					let TMList = res.data.data
					this.getExamTm(TMList)
				})
			},
			getExamTm(TMList) {

				this.$http.post("/api/t_exam_tmlist", {
					id: this.formData.id
				}).then(res => {
					let arr = [];
					//过滤掉已经在试卷中的题目
					let extTmDic = {};
					let totalScore = 0;
					for (let item of res.data) {
						extTmDic[item.id] = 1;
						if (item.score > 0) {
							totalScore += item.score;
						}
					}

					for (let tm of TMList) {
						if (!extTmDic[tm.id]) {
							arr.push(tm)
						}
					}
					this.ExamTMList = res.data
					this.TMList = arr
					this.totalScore = totalScore;
				})
			},



			handleSelectionChange1(e) {
				this.choosedTm1 = e;
			},
			handleSelectionChange2(e) {
				this.choosedTm2 = e;
			},
			setChooseTm() {
				this.$http.post("/api/t_exam_add_tm", {
					exam_id: this.formData.id,
					ids: this.choosedTm1.map(e => {
						return e.id
					}).join(",")
				}).then(res => {
					this.getTkTm()
				})
			},
			cancelChooseTm() {
				this.$http.post("/api/t_exam_remove_tm", {
					exam_id: this.formData.id,
					ids: this.choosedTm2.map(e => {
						return e.rid
					}).join(",")
				}).then(res => {
					this.getTkTm()
				})
			},
			saveSort(row) {
				this.editrowid = 0;
				this.$http.post("/api/t_exam_tm_sort", {
					id: row.rid,
					sort: row.sort
				}).then(res => {
					this.$message.success("修改成功")
					this.getTkTm()
				})
			},
			showJoinUsers(e){
				
				this.editrowid = e.id
				
				this.examTitle = e.exam_title
				this.showUsers = true;
			},
			createRmTm(){
				
				this.$http.post("/api/exam_random_tm", {
					exam_id:this.formData.id,
					course_id:this.formData.c_id,
					radio:this.suiji.radio,
					checkbox:this.suiji.checkbox,
					panduan:this.suiji.panduan,
				}).then(res => {
					this.$message.success("添加成功")
					this.dialogTmSjShow =false
					this.getTkTm()
				})
			},
			uploadFiles(e){
				this.$http.post("/api/import_exam_tm", {
					url: e.src,
					exam_id:this.formData.id,
					course_id:this.formData.c_id
				}).then(res => {
					this.$message.success("添加成功")
					this.showExport =false
					this.getTkTm()
				})
			}




		}
	}
</script>

<style>
</style>
